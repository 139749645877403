<template>
  <div>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="widget-type"
              class="form-control"
              v-model="widgetDetails.widgetCategoryID"
              @input="onChangeWidgetType($event)"
              :disabled="isEditPage"
              :class="{
                'is-value-exist': widgetDetails.widgetCategoryID != null,
                'is-invalid': (!widgetDetails.widgetCategoryID && formSubmitted) || restrictAddWidgetToMaxAllowed,
              }"
            >
              <template v-for="(type, index) in applicableWidgets">
                <b-form-select-option v-if="type.isSelected" :key="index" :value="type.widgetCategoryId">{{ type.widgetCategoryName }}</b-form-select-option>
              </template>
            </b-form-select>
            <label for="widget-type">Widget type</label>
            <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.widgetCategoryID && formSubmitted">Widget type required.</b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-html="DISPLAY_MESSAGES.MAX_WIDGET_TYPE_ERROR"
              class="d-block"
              v-if="restrictAddWidgetToMaxAllowed"
            ></b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col class="pt-md-2 mt-md-1">
        <b-form-group v-if="showIntegrationPropertyDependent">
          <b-form-checkbox
            id="is-Property-dependent"
            name="is-Property-dependent"
            @change="validateLinkAccount($event)"
            :disabled="isAddressSetupDone"
            v-model="widgetDetails.integrationWidgetSettings.isPropertyDependent"
          >
            Property dependent
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="widgetDetails.widgetCategoryID == 6">
          <b-form-checkbox
            id="is-Property-dependent"
            name="is-Property-dependent"
            :disabled="isAddressSetupDone"
            v-model="widgetDetails.formBuilderWidgetSettings.isPropertyDependent"
          >
            Property dependent
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="(showIntegrationPropertyDependent || widgetDetails.widgetCategoryID == 6) && isAddressSetupDone">
      <b-col>
        <h6 class="font-14">
          <InfoCircelYellow class="mr-1 mt-n1" />
          {{ DISPLAY_MESSAGES.ADDRESS_SETUP_NOT_DONE_WIDGET_ERROR }}
        </h6>
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetDetails.widgetCategoryID == 4">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="widget-sub-type"
              class="form-control"
              v-model="widgetDetails.locationWidgetSettings.isFormat"
              @change="onChangeFormatResults('locationWidgetSettings')"
              @input="widgetDetails.locationWidgetSettings.isFormat = $event"
              :disabled="isEditPage"
              :class="{
                'is-value-exist': widgetDetails.locationWidgetSettings.isFormat != null,
              }"
            >
              <b-form-select-option :value="false">HTML widget</b-form-select-option>
              <b-form-select-option :value="true">Formatted widget</b-form-select-option>
            </b-form-select>
            <label for="widget-sub-type">Widget sub-type</label>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <template v-if="widgetDetails.widgetCategoryID == 5">
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="widget-sub-type"
                class="form-control"
                v-model="widgetDetails.integrationWidgetSettings.isFormat"
                @change="onChangeFormatResults('integrationWidgetSettings')"
                @input="widgetDetails.integrationWidgetSettings.isFormat = $event"
                :disabled="isEditPage"
                :class="{
                  'is-value-exist': widgetDetails.integrationWidgetSettings.isFormat != null,
                }"
              >
                <b-form-select-option :value="false">HTML widget</b-form-select-option>
                <b-form-select-option :value="true">Formatted widget</b-form-select-option>
              </b-form-select>
              <label for="widget-sub-type">Widget sub-type</label>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="authentication-method"
                class="form-control"
                v-model="widgetDetails.integrationWidgetSettings.authenticationType"
                @input="widgetDetails.integrationWidgetSettings.authenticationType = $event"
                @change="onChangeAuthenticationMethod"
                :class="{
                  'is-value-exist': widgetDetails.integrationWidgetSettings.authenticationType != null,
                  'is-invalid': !widgetDetails.integrationWidgetSettings.authenticationType && formSubmitted,
                }"
              >
                <b-form-select-option :value="method.value" v-for="(method, index) in authenticationMethods" :key="index">{{
                  method.name
                }}</b-form-select-option>
              </b-form-select>
              <label for="authentication-method">Select authentication method</label>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
    <b-form-row v-if="widgetDetails.widgetCategoryID == 8">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="mywaste-platform"
              class="form-control"
              v-model="widgetDetails.myWasteWidgetSettings.platform"
              @input="widgetDetails.myWasteWidgetSettings.platform = $event"
              :class="{
                'is-value-exist': widgetDetails.myWasteWidgetSettings.platform != null,
                'is-invalid': !widgetDetails.myWasteWidgetSettings.platform && formSubmitted,
              }"
            >
              <b-form-select-option v-for="(platform, index) in myWastePlatformList" :key="index" :value="platform">{{ platform }}</b-form-select-option>
            </b-form-select>
            <label for="mywaste-platform">Waste collection platform</label>
            <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.myWasteWidgetSettings.platform && formSubmitted"
              >Waste collection platform required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetDetails.widgetCategoryID == 7">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="engagement-platform"
              class="form-control"
              v-model="widgetDetails.engagementWidgetSettings.engagementPlatform"
              @input="widgetDetails.engagementWidgetSettings.engagementPlatform = $event"
              :class="{
                'is-value-exist': widgetDetails.engagementWidgetSettings.engagementPlatform != null,
                'is-invalid': !bangTheTableIsValid && formSubmitted,
              }"
            >
              <b-form-select-option value="bang-the-table">Bang the table</b-form-select-option>
            </b-form-select>
            <label for="engagement-platform">Engagement platform</label>
            <b-form-invalid-feedback class="d-block" v-if="!bangTheTableIsValid && formSubmitted"
              >Bang the table configuration required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <b-button type="button" id="engagement-manage-account" v-activeBlur variant="primary" v-b-toggle.bang-the-table-sidebar>Manage account </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetDetails.widgetCategoryID == 9">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="recreation-platform"
              class="form-control"
              v-model="widgetDetails.recreationWidgetSettings.recreationPlatform"
              @input="widgetDetails.recreationWidgetSettings.recreationPlatform = $event"
              :class="{
                'is-value-exist': widgetDetails.recreationWidgetSettings.recreationPlatform != null,
                'is-invalid': !perfectMindConfigIsValid && formSubmitted,
              }"
            >
              <b-form-select-option value="perfect-mind">Perfect mind</b-form-select-option>
            </b-form-select>
            <label for="recreation-platform">Recreation platform</label>
            <b-form-invalid-feedback class="d-block" v-if="!perfectMindConfigIsValid && formSubmitted"
              >Perfect mind configuration required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <b-button type="button" v-activeBlur id="recreation-manage-account" variant="primary" v-b-toggle.perfect-mind-sidebar>Manage account </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              id="widget-name"
              type="text"
              placeholder=" "
              v-model="widgetDetails.widgetName"
              required
              :class="{
                'is-invalid': !widgetDetails.widgetName && formSubmitted,
              }"
            ></b-form-input>
            <label for="widget-name">Widget name</label>
            <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.widgetName && formSubmitted">Widget name required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="10" md="6" lg="5" xl="4" class="pt-md-2 mt-md-1">
        <b-form-group>
          <b-form-checkbox id="is-fixed-widget" name="is-fixed-widget" v-model="widgetDetails.isFixedWidget"> Set as fixed widget </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              id="widget-color"
              type="text"
              placeholder=" "
              v-model="widgetDetails.widgetColor"
              @focus="showColorPicker = true"
              @blur="showColorPicker = false"
              @input="applyColorToPicker"
              :class="{
                'is-invalid': (!widgetDetails.widgetColor && formSubmitted) || !isValidColor,
              }"
              required
            ></b-form-input>
            <label for="widget-color">Widget color</label>
            <color-picker
              v-if="showColorPicker"
              theme="dark"
              :color="widgetDetails.widgetColor"
              :sucker-hide="true"
              @input="changeColor"
              @changeColor="changeColor"
              class="color-picker"
            />
          </div>
          <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.widgetColor && formSubmitted">Widget color required.</b-form-invalid-feedback>
          <b-form-invalid-feedback :state="isValidColor">Invalid color</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <h4 class="page-sub-header">Widget icon</h4>
          <b-form-radio-group v-model="widgetDetails.isIconUploaded" id="widget-icon" name="widget-icon" aria-describedby="Widget icon option selection">
            <b-form-radio :value="false">Select icon from library </b-form-radio>
            <b-form-radio :value="true">Upload icon </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!widgetDetails.isIconUploaded">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <button
            type="button"
            class="btn-block select-icon-btn preview-logo-wraper"
            @click="showWidgetIconsModal = true"
            :class="{
              'is-invalid': !widgetDetails.widgetLogoBlobURI && formSubmitted,
            }"
          >
            <span
              :style="{
                backgroundColor: widgetDetails.widgetColor ? widgetDetails.widgetColor : `#ccc`,
              }"
              class="preview-logo"
              ><img v-if="widgetDetails.widgetLogoBlobURI" :src="widgetDetails.widgetLogoBlobURI" class="widget-list-icon" /></span
            >{{ widgetDetails.widgetLogoBlobURI ? 'Change icon' : 'Select icon' }}
          </button>
          <IconsList v-if="showWidgetIconsModal" :savedIcon="widgetDetails.widgetLogoBlobURI" @closeWidgetIconsModal="closeWidgetIconsModal" />
          <b-form-invalid-feedback class="d-block pb-2" v-if="!widgetDetails.widgetLogoBlobURI && formSubmitted">Widget icon required.</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetDetails.isIconUploaded">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <button
            type="button"
            for="widget-logo-file-upload"
            class="btn-block select-icon-btn preview-logo-wraper"
            :class="{
              'is-invalid': !widgetDetails.widgetLogo && !widgetDetails.widgetLogoBlobURI && formSubmitted,
            }"
          >
            <span
              v-if="widgetDetails.widgetLogo"
              v-html="widgetDetails.widgetLogo"
              :style="{
                backgroundColor: widgetDetails.widgetColor ? widgetDetails.widgetColor : `#ccc`,
              }"
              class="preview-logo"
            ></span>
            <span
              v-else
              :style="{
                backgroundColor: widgetDetails.widgetColor ? widgetDetails.widgetColor : `#ccc`,
              }"
              class="preview-logo"
              ><img v-if="widgetDetails.widgetLogoBlobURI" :src="widgetDetails.widgetLogoBlobURI" class="widget-list-icon"
            /></span>
            <span>{{ widgetDetails.widgetLogo || widgetDetails.widgetLogoBlobURI ? 'Change icon' : 'Upload icon' }}</span>
            <b-form-file
              id="widget-logo-file-upload"
              class="cutom-file-input"
              accept="image/svg+xml,.svg"
              @change="changeWidgetLogo"
              v-model="fileNameDetails"
              plain
            ></b-form-file>
          </button>
          <b-form-invalid-feedback class="d-block pb-2" v-if="!widgetDetails.widgetLogo && !widgetDetails.widgetLogoBlobURI && formSubmitted"
            >Widget icon required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
    <template
      v-if="selectedTenant.length && widgetDetails.widgetCategoryID == 7 && widgetDetails.engagementWidgetSettings.engagementPlatform == 'bang-the-table'"
    >
      <b-sidebar
        id="bang-the-table-sidebar"
        body-class="custom-scroll"
        aria-labelledby="Bang the table configuration"
        aria-label="Bang the table configuration"
        shadow
        bg-variant="white"
        :backdrop-variant="`dark`"
        no-close-on-esc
        no-close-on-backdrop
        backdrop
        right
        width="35vw"
      >
        <template #header="{ hide }">
          <h3 class="font-21 theme-font-medium mb-0">Bang the table configuration</h3>
          <div class="sidebar-header-close">
            <a href="javascript:void(0)" aria-labelledby="Bang the table configuration title" @click="hide"
              ><img src="../../../assets/img/close.png" alt="close-tab"
            /></a>
          </div>
        </template>
        <template #default="{}">
          <BangTheTable
            :tenantServiceId="selectedTenant[0].tenantServiceId"
            @bangTheTableConfigUpdateRes="bangTheTableConfigUpdateRes"
            @bangTheTableIsValidConfiguration="bangTheTableIsValidConfiguration"
          />
        </template>
        <template #footer="{ hide }">
          <b-button type="button" v-activeBlur class="btn-h-44" variant="outline-secondary" @click="hide">Close</b-button>
        </template>
      </b-sidebar>
    </template>
    <PerfectMindSidebar
      v-if="selectedTenant.length && widgetDetails.widgetCategoryID == 9 && widgetDetails.recreationWidgetSettings.recreationPlatform == 'perfect-mind'"
      :tenantServiceId="selectedTenant[0].tenantServiceId"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import IconsList from './IconsList.vue'
import colorPicker from '@caohenghu/vue-colorpicker'
import InfoCircelYellow from '../../../assets/svg/info-circle-yellow.svg'
import { DISPLAY_MESSAGES, WidgetLogoFileSizeLimit, WidgetTypesSettings, ColorRegEx } from '../../../utilities/constants'
import { AUTHENTICATION_METHODS, AUTHENTICATION_METHODS_ENUMS } from '@/utilities/widget-constants'
export default {
  name: 'Configuration',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted'],
  components: {
    IconsList,
    InfoCircelYellow,
    colorPicker,
    BangTheTable: () => import('./BangTheTable.vue'),
    PerfectMindSidebar: () => import('./perfect-mind-sidebar/PerfectMindSidebar.vue'),
  },
  data() {
    return {
      showColorPicker: false,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      widgetLogoFileSizeLimit: WidgetLogoFileSizeLimit,
      widgetTypesSettings: WidgetTypesSettings,
      ColorRegEx: ColorRegEx,
      showWidgetIconsModal: false,
      fileNameDetails: null,
      bangTheTableSidebarFg: false,
      myWastePlatformList: [],
      authenticationMethods: AUTHENTICATION_METHODS,
    }
  },
  computed: {
    isAddressSetupDone() {
      return !!(this.selectedTenant.length && !this.selectedTenant[0].isAddressSetupDone)
    },
    restrictAddWidgetToMaxAllowed() {
      return (
        !this.isEditPage &&
        this.applicableWidgets.some((widget) => widget.widgetCategoryId === this.widgetDetails.widgetCategoryID && widget.existingCnt >= widget.allowedCnt)
      )
    },
    isValidColor() {
      if (this.widgetDetails.widgetColor) {
        return this.widgetDetails.widgetColor.match(ColorRegEx) !== null
      }
      return true
    },
    showIntegrationPropertyDependent() {
      return this.widgetDetails.widgetCategoryID === 5
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant,
      applicableWidgets: (state) => state.widgets.applicableWidgets,
      linkAccounts: (state) => state.settings.linkAccounts,
      bangTheTableIsValid: (state) => state.widgets.bangTheTableIsValid,
      perfectMindConfigIsValid: (state) => state.widgets.perfectMindConfigIsValid,
    }),
  },
  created() {
    this.getApplicableWidgets()
    this.getMyWastePlatform()
  },
  methods: {
    getApplicableWidgets() {
      if (this.selectedTenant.length) {
        this.$store.dispatch('widgets/getApplicableWidgets', this.userRole === 'SysAdmin' ? this.widgetDetails.tenantID : null)
      }
    },
    getMyWastePlatform() {
      if (this.widgetDetails.widgetCategoryID === 8 && this.selectedTenant.length) {
        this.myWastePlatformList = []
        this.$store
          .dispatch('widgets/getMyWastPlatform', this.userRole === 'SysAdmin' ? this.widgetDetails.tenantID : null)
          .then((res) => {
            this.myWastePlatformList = res.data ? res.data : []
          })
          .catch(() => {
            this.myWastePlatformList = []
          })
      }
    },
    onChangeWidgetType(widgetCategoryID) {
      this.widgetDetails.widgetCategoryID = widgetCategoryID
      if (!this.isEditPage) {
        this.$router.replace(`/widgets/add-widget/${widgetCategoryID}`)
      }
      const settings = this.widgetTypesSettings.find((setting) => setting.widgetCategoryID === widgetCategoryID)
      if (settings) {
        this.widgetDetails[settings.name].isNotified = false
      }
      this.$emit('setDisplayTab')
    },
    updateWidgetCategoryId() {
      const filteredApplicableWidges = this.applicableWidgets.filter((widget) => widget.isSelected)
      const index = filteredApplicableWidges.findIndex((widget) => widget.widgetCategoryId === Number(this.$route.params.widgetCategoryId))
      if (index === -1) {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'WIDGET_NOT_ENABLED',
          type: 'danger',
        })
        this.$router.push('/widgets').catch(() => {})
      }
    },
    onChangeFormatResults(type) {
      this.widgetDetails[type].selectedView = 'form-view'
      this.widgetDetails[type].dashboardItemCnt = null
      if (!this.widgetDetails[type].isFormat) {
        this.widgetDetails[type].isNotified = false
      }
      this.$emit('setDisplayTab')
    },
    onChangeAuthenticationMethod() {
      if (this.widgetDetails.integrationWidgetSettings.authenticationType === AUTHENTICATION_METHODS_ENUMS.IDENTITY_PROVIDER) {
        this.widgetDetails.integrationWidgetSettings.linkAccountDetails = null
        this.widgetDetails.integrationWidgetSettings.unlinkedWidgetTxt = null
        this.widgetDetails.integrationWidgetSettings.linkAccountTxt = null
        this.widgetDetails.integrationWidgetSettings.unLinkAccountTxt = null
      }
      this.widgetDetails.integrationWidgetSettings.isPropertyDependent = !this.isAddressSetupDone
      this.$emit('setDisplayTab')
    },
    changeColor(color) {
      this.widgetDetails.widgetColor = color.hex
    },
    applyColorToPicker() {
      this.showColorPicker = false
      this.$nextTick(() => {
        this.showColorPicker = true
      })
    },
    closeWidgetIconsModal(event) {
      if (event) {
        this.widgetDetails.widgetLogoBlobURI = event
      }
      this.showWidgetIconsModal = false
    },
    changeWidgetLogo(ev) {
      if (ev.target.files && ev.target.files.length) {
        this.fileNameDetails = ev.target.files[0]
        const fileName = ev.target.files[0].name
        const idxDot = fileName.lastIndexOf('.') + 1
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        const fileSizeError = this.fileNameDetails.size > this.widgetLogoFileSizeLimit
        if (extFile === 'svg' && !fileSizeError) {
          const reader = new FileReader()
          reader.readAsText(ev.target.files[0])
          reader.onload = (event) => {
            this.widgetDetails.widgetLogo = event.target.result
          }
        } else {
          const msg = extFile !== 'svg' ? 'Only SVG files are allowed!' : fileSizeError ? 'File should be less than 5 KB.' : ''
          this.$bvModal.msgBoxOk(msg, {
            title: 'Warning',
            size: 'md',
            buttonSize: 'lg',
            okVariant: 'danger',
            bodyClass: 'text-center',
            headerClass: 'p5-5 theme-font-bold justify-content-center border-bottom-0',
            footerClass: 'justify-content-center border-top-0',
            centered: true,
          })
          ev.target.value = ''
          this.fileNameDetails = null
          this.widgetDetails.widgetLogo = null
        }
      }
    },
    validateLinkAccount(event) {
      if (!event && this.widgetDetails.integrationWidgetSettings.linkAccountDetails) {
        const selectedLinkAccount = this.linkAccounts.find(
          (linkAccount) =>
            linkAccount.authenticationType === this.widgetDetails.integrationWidgetSettings.linkAccountDetails.authenticationType &&
            linkAccount.id === this.widgetDetails.integrationWidgetSettings.linkAccountDetails.id
        )
        if (selectedLinkAccount && selectedLinkAccount.isForEachProperty) {
          this.widgetDetails.integrationWidgetSettings.linkAccountDetails = null
        }
      }
    },
    bangTheTableIsValidConfiguration(isValid) {
      this.$store.commit('widgets/setBangTheTableIsValid', isValid)
    },
    bangTheTableConfigUpdateRes(res) {
      if (Object.hasOwn(res, 'data')) {
        if (res.data) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'BANG_THE_TABLE_UPDATE_SUCCESS',
            type: 'success',
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: res.error,
            key: false,
            type: 'danger',
          })
        }
      } else {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'FAILED',
          type: 'danger',
        })
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getApplicableWidgets()
      this.getMyWastePlatform()
    },
    applicableWidgets(newValue, oldValue) {
      if (this.selectedTenant.length) {
        this.updateWidgetCategoryId()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-logo-wraper {
  align-items: normal;
  display: flex;
  padding-top: 1.5rem !important;
  .preview-logo {
    border: 1px solid #ccc;
    width: 48px;
    height: 48px;
    border-radius: 7px 0;
  }
}
.select-icon-btn {
  padding: 0 2rem 0 0 !important;
  align-items: center;
  height: 48px;
  color: #000;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  .preview-logo {
    margin: 1px 1rem 1px 1px;
    width: 46px;
    height: 44px;
    border: 0;
    border-radius: 3px;
    svg {
      width: 25px;
      height: 22px;
    }
    .widget-list-icon {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
